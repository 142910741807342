body {
    overflow: overlay;
}

.required {
    color: red;
}

.otp-container > div {
    /* justify-content: space-evenly; */
    display: flex;
    width: 100%;
    place-content: center;
}

.otp-container > div > div > input {
    width: 3em !important;
    margin: 2px;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #bebebe;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #888;
}

.react-tel-input > input {
    width: 100% !important;
    padding: 7px 7px 7px 56px !important;
    border: 1px solid #dee2e6 !important
}

.react-tel-input > input:focus {
    border: 1px solid #c8cbcf !important
}

.cursor-pointer {
    cursor: pointer;
}

#recaptcha-container > div > div {
    width: auto !important;
}

/* hide arrows from input type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.text-right {
    text-align: right;
}

.float-right {
    float: right;
}

.align-self-center {
    align-self: center;
}

.height-fit-content {
    height: fit-content;
}

.form-select:disabled {
    border: 1px solid var(--ct-input-border-color);
}

.phone-input input {
    padding: 6px 14px 6px 60px !important
}

.default-img {
    height: 150px;
    object-fit: contain;
    width: 100%;
}

.BrainhubCarousel__arrows {
    border-radius: 5px;
}

.BrainhubCarousel__thumbnail img {
    height: 60px;
    width: 60px;
    object-fit: contain;
}

.BrainhubCarousel__thumbnail {
    border-radius: 5px !important;
    margin: 2px;
}

.BrainhubCarouselItem img {
    width: inherit;
}

.product-slider-image {
    padding: 8px;
}

.color-available {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 1px solid #cdcdcd !important;
}

.top-logo {
    max-width: 200px;
    max-height: 48px;

    min-width: 50px;
    min-height: 48px;

    object-fit: contain;
}


.product-price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: end;
    margin: 15px 0 0;
}

.current-price {
    font-size: 50px;
    font-weight: 600;
}

.old-price {
    text-decoration: line-through;
    margin-left: 20px;
    font-size: 20px;
    font-weight: 500;
    display: block;
}

.save-price {
    margin-left: 20px;
    font-size: 12px;
    font-weight: 400;
    display: block;
}

.current-price-small {
    /* font-size: 24px; */
    font-size: 18px;
    font-weight: 600;
}

.old-price-small {
    text-decoration: line-through;
    margin-left: 8px;
    font-size: 12px;
    font-weight: 500;
    display: block;
}

.save-price-small {
    margin-left: 8px;
    font-size: 8px;
    font-weight: 400;
    display: block;
}

.variant-img {
    height: 80px;
    object-fit: contain;
    width: 80px;
}

.product-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.zoom-image {
    transform: scale(1);
    transition: transform 0.5s;
}

.zoom-image:hover {
    transform: scale(1.1);
    transition: transform 0.5s;
}

.top-bar-icons {
    width: max-content;
    float: right;
}

.order-status-icon {
    width: 100px;
    height: 100px;
}

.order-summary-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    padding: 4px;
}

.top-bar-dropdown-menu {
    position: absolute;
    inset: 0 0 auto auto;
    margin: 0;
    transform: translate(-156px);
}

.selected-card {
    outline: 1px solid var(--bs-primary);
}

.progress-bar-height {
    height: 6px;
}

.slider-container {
    height: 300px;
}

.carousel-inner {
    height: 300px;
    border-radius: 4px;
}

.carousel-item {
    height: 300px;
}

.carousel-item img {
    height: 300px;
    border-radius: 4px;
    object-fit: cover;
}

@media screen and(max-width: 767px) {
    .order-item-first {
        order: -1;
    }
    .order-item-second {
        order: -1;
    }
}


.ribbon-box .ribbon-two {
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right
}

.ribbon-box .ribbon-two span {
    font-size: 12px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 100px;
    display: block;
    -webkit-box-shadow: 0 0 8px 0 rgba(49, 58, 70, 0.08), 0 1px 0 0 rgba(49, 58, 70, 0.03);
    box-shadow: 0 0 8px 0 rgba(49, 58, 70, 0.08), 0 1px 0 0 rgba(49, 58, 70, 0.03);
    position: absolute;
    top: 19px;
    left: -21px;
    font-weight: 600
}

.ribbon-box .ribbon-two span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    z-index: -1;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent
}

.ribbon-box .ribbon-two span:after {
    content: "";
    position: absolute;
    right: 0;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent
}

.ribbon-box .ribbon-two-primary span {
    background: var(--custom-primary)
}

.ribbon-box .ribbon-two-danger span {
    background: #db3030
}

.ribbon-box .ribbon-two-danger span:before {
    border-left: 3px solid #db3030;
    border-top: 3px solid #db3030
}

.ribbon-box .ribbon-two-danger span:after {
    border-right: 3px solid #db3030;
    border-top: 3px solid #db3030
}

.disable-image {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
}

.object-fit-contain {
    object-fit: contain;
}

.search-drop-down {
    max-height: 500px;
    overflow: auto;
}

header {
    position: fixed;
    background-color: white;
    width: 100%;
    z-index: 2;
}

main {
    padding-top: 56px;
}

@media(min-width:576px) {
    main {
        padding-top: 56px;
    }
}

@media(min-width:768px) {
    main {
        padding-top: 56px;
    }
}

@media(min-width:992px) {
    main {
        padding-top: 108px;
    }
}

@media(min-width:1200px) {
    main {
        padding-top: 108px;
    }
}

@media(min-width:1400px) {
    main {
        padding-top: 108px;
    }
}


.swiper {
    width: 100%;
    min-height: 100px;
    max-height: 300px;
    border-radius: 8px;
    margin-top: 20px;
    /* margin-bottom: 20px; */
}

.swiper-slide-active{
    min-height: 100px !important;
    max-height: 300px !important;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: auto !important;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.autoplay-progress {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 2;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: var(--bs-primary);
}

.autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: var(--bs-primary);
    fill: none;
    stroke-dashoffset: calc(125.6 *(1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
}

.swiper-pagination-bullet-active {
    background-color: var(--bs-primary) !important;
}